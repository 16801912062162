export const siteRefId = {
  sweden: "c8f4261f9f46e6465709e17ebea7a92b",
  norway: "d5b9290a0b67727d4ba1ca6059dc31a6",
};

export const massBalanceType = {
  TR: "tr",
  TRS: "trs",
  CPP: "cpp",
};

export const countryNames = {
  SWEDEN: "sweden",
  NORWAY: "norway",
  UK: "uk",
  UNITED_KINGDOM: "UNITED KINGDOM",
  SPAIN: "SPAIN",
  GERMANY: "GERMANY",
};

export const mbStatus = {
  OPEN: "OPEN",
  CLOSED: "CLOSED",
};

export const status = {
  PENDING: "PENDING",
  AVAILABLE: "AVAILABLE",
  AVAILABLE_CO: "CARRIED FORWARD",
  AVAILABLE_FROM_TRANSFER: "AVAILABLE_FROM_TRANSFER", // This is only for FE not available in BE
  ALLOCATED: "ALLOCATED",
  ALLOCATED_AND_SENT: "ALLOCATED AND SENT",
  RETIRED: "RETIRED",
  WRITE_OFF: "WRITTEN OFF",
  CARRIED_OVER: "CARRIED OVER",
  INVALID_PURCHASE: "INVALID PURCHASE",
  INVALID_OUTGOING: "INVALID OUTGOING",
  GENERATED: "GENERATED",
  RESERVED: "RESERVED",
  TRANSFERRED: "TRANSFERRED",
  TRANSFERRED_AND_SENT: "TRANSFERRED AND SENT",
  RETIRED_FOR_SNS: "RETIRED FOR SNS",
  CARRY_OVER: "CARRY OVER",
};

export const INCOMING = "Incoming";
export const OUTGOING = "Outgoing";
export const MANDATE_INCOMING = "MandateIncoming";
export const MANDATE_OUTGOING = "MandateOutgoing";
export const COPROCCESSED = "Co-processed";
export const INBOUND_RECORD = "inboundRecord";
export const OUTBOUND_RECORDS = "outboundRecords";
export const COPROCESSED_RECORD = "coProcessedRecord";
export const MANDATE_INBOUND_RECORD = "inboundRecords";
export const MANDATE_OUTBOUND_RECORD = "outboundRecord";

export const parentMapping = {
  [INCOMING]: INBOUND_RECORD,
  [OUTGOING]: OUTBOUND_RECORDS,
  [MANDATE_INCOMING]: MANDATE_INBOUND_RECORD,
  [MANDATE_OUTGOING]: MANDATE_OUTBOUND_RECORD,
};

export const PINNED_COLUMNS_LIST = ["status", "actions"];

export const DATE_RANGE_SIZE = 2;

export const actionItems = {
  LINK_DOCUMENT: "Link document",
  UNLINK_DOCUMENT: "Unlink document",
  VIEW_DETAILS: "View details",
  ALLOCATE: "Allocate",
  REMOVE_ALLOCATION: "Remove allocation",
  UNRETIRE_FOR_MANDATE: "Unretire for mandate",
  RETIRE_FOR_MANDATE: "Retire for mandate",
  RESERVE: "Reserve",
  EDIT_RESERVATION: "Edit reservation",
  REMOVE_RESERVATION: "Remove reservation",
  TRANSFER: "Transfer",
  GENERATE_DOCUMENT: "Generate document",
  WRITE_OFF: "Write off",
  REMOVE_FROM_BALANCE: "Remove from balance",
  REMOVE_DOCUMENT: "Remove document",
  REMOVE_WRITE_OFF: "Remove write off",
  REMOVE_PURCHASE: "Remove purchase",
  REMOVE_OUTGOING: "Remove outgoing transaction",
  REMOVE_TRANSFER: "Remove transfer",
  RETIRE_FOR_MANDATE_SNS: "Retire volume for SNS",
  UNRETIRE_FOR_VOLUME_SNS: "Unretire volume for SNS",
};

export const reversalEventTypes = {
  REVERT: "revert",
  REMOVE: "remove",
};

export const reversalEventNames = {
  ACTUALIZATION: "actualization",
  ALLOCATION: "allocation_v2",
  CARRY_FORWARD: "carry_forward",
  LOCATION_TRANSFER: "location_transfer",
  REMOVE_BALANCE: "remove_balance",
  RESERVED: "reserved",
  WRITE_OFF: "write_off",
  OUTGOING_DOCUMENTS: "outgoing_documents",
  INVALID_PURCHASE: "invalid_purchase",
  INVALID_SALE: "invalid_sale",
  REOPEN_MASS_BALANCE: "reopen",
  UNRETIRE_FOR_MANDATE: "retired",
  UNRETIRE_FOR_VOLUME_SNS: "retired",
  REMOVE_PURCHASE: "invalid_purchase",
  NABISY_DOCUMENT: "nabisy_document",
  REMOVE_OUTGOING: "invalid_sale",
};

export const MASS_BALANCE_CLOSE_SUCCESS_HEADER = "Balance closed";
export const MASS_BALANCE_CLOSE_SUCCESS_BODY =
  "Sucessfully closed this mass balance. Re-open if you need to edit the balance.";
export const MASS_BALANCE_CLOSE_ERROR_HEADER = "Balance cannot be closed";

export const MASS_BALANCE_REOPEN_ERROR_HEADER = "Balance cannot be re-open";

export const MASS_BALANCE_CLOSING_API_MODAL = "mass-balance-closing-api-modal";
export const MASS_BALANCE_CLOSING_CONFIRMATION_MODAL =
  "mass-balance-closing-confirmation-modal";
export const MASS_BALANCE_CLOSING_PHYSICAL_WITH_API_MODAL =
  "mass-balance-closing-physical-with-api-modal";

export const MB_QUANTITY_UNIT_MJ = "g CO2eq/MJ";
export const MB_QUANTITY_UNIT_KG = "kg CO2eq/dry-ton";
